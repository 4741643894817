import { memo, useMemo } from 'react'
import type { NextPage } from 'next'
import { callAPI } from '@/api'
import { callLocales } from '@/i18n'
import styled from 'styled-components'
import { HeadTag } from '@dy/commons/components'
import { Layout, SectionExplore, SectionContact, SectionFavourites, Header, SectionContents } from '../components'

import { mediaQuery, getRelative } from '@/styles'
import { useScrollTop } from '@dy/commons/hooks'

interface IHomePage {
  data: any,
}

const Main = styled.main`
  section {
    &:not([class*='SectionContents__']) {
      margin-bottom: ${getRelative(100, 'mobile')};
    }

    &:first-of-type {
      margin-top: ${getRelative(100, 'mobile')};
    }

    &:nth-child(4) {
      margin-bottom: ${getRelative(30, 'mobile')};
    }

    ${mediaQuery.greaterThan('tablet')`
      &:not([class*='SectionContents__']) {
        margin-bottom: ${getRelative(130, 'desktop')};
      }

      &:first-of-type {
        margin-top: ${getRelative(130, 'desktop')};
      }

      &:nth-child(4) {
        margin-bottom: ${getRelative(30, 'desktop')};
      }
    `}
  }
`

export async function getServerSideProps({ locale }) {
  const [data, error] = await callAPI({ type: 'home', params: { locale, slug: 'home' } })
  const [layout_data, error_layout] = await callAPI({ type: 'layout', params: { locale } })
  const [locales] = await callLocales({ locale })

  if (error || error_layout || !data) return { notFound: true }

  return {
    props: {
      data,
      locales,
      layout_data
    }
  }
}

const getContentsSections = (sections) => {
  return sections.map((section, idx) => {
    return (
      <SectionContents key={idx} data={section} />
    )
  })
}

const HomePage: NextPage<IHomePage> = memo(({ data }) => {
  const { seo, header, contents, projects, highlighted_product } = data;
  const ContentsSections = useMemo(() => getContentsSections(contents), [contents])
  useScrollTop()

  return (
    <>
      <HeadTag data={seo} pageType='home' slug='' jsonld={{ data: { seo }, pageType: 'home' }}/>
      <Header data={header} homepage/>
      <Main>
        {ContentsSections}
        <SectionExplore data={projects} />
        {highlighted_product && <SectionFavourites data={highlighted_product} />}
        <SectionContact />
      </Main>
    </>
  )
})

//@ts-ignore
HomePage.Layout = Layout
export default HomePage
